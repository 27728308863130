<template>
  <div class="body-main">
    <div class="cart-header"><div class="cart-header-warp clearfix"><div class="cart-title left clearfix"><h1 class="left">确认订单</h1></div><div id="orderCartBanner"></div><div class="right"></div></div></div>
    <input type="hidden" id="modeHiddenInput" />
    <div class="cart-body" id="cartBody">
      <div class="title-box clearfix">
        <p class="l goods-info-title">商品信息</p>
       <!-- <a href="javascript:void(0);" class="r js-show-meiqia">我有疑问，需要反馈？</a>-->
      </div>
      <div class="detail-box">
        <ul>
          <li class="clearfix js-item-cart js-each-131 js-every-2371" v-for="(v,i) in tableData" :key="i">
            <a href="" target="_blank"><img :src="v.imagesmall" alt="" class="l"  /></a>
            <div class="text-info-box l">
              <p class="package-title"></p>
              <a href="" target="_blank" ><p class="package-info-title package-info-title4">{{v.coursename}}</p></a>
              <p class="info-del">视频不限期，老师专属答疑，1v1作业批复，学练测评一体化</p>
              <p class="class-three-servicetime">购买成功后将立即开启教学服务
              <!--  <a href="https://www.imooc.com/help/detail/95" target="_blank">什么是教学服务？</a>-->

              </p>
            </div>
            <div class="info-price info-price1 l">
              <span class="discount-name need-hide">到手价</span>
              <span class="dicount-price"><em>￥</em><span class="price">{{v.cusamountjs}}</span></span>
              <span class="init-price need-hide"><em>￥</em><span class="price">{{v.cusamountjs}}</span></span>
            </div>
            <div class="info-price group-class l hide">
              <span class="discount-name">到手价</span>
              <span class="dicount-price"><em>￥</em><span class="price"></span></span>
              <span class="init-price"><em>￥</em><span class="price"></span></span>
            </div>
          </li>
        </ul>
      </div>
     <!-- <div class="coupons-box js-isLearning-path">
        <div class="coupon-title-box clearfix">
          <p class="coupon-title l">使用优惠券/优惠码<i @click="active=!active" :class="{'imv2-arrow2_d':!active,'imv2-arrow2_u':active}" class="js-more-coupon-btn js-close"></i></p>
          <a href="//www.imooc.com/help/detail/73" target="_blank" class="r">什么是优惠券？</a>
        </div>
        <div v-show="active" class="coupon-del-box">
          <div class="coupon-switch-box">
            <div class="switch-btn ticket checked">优惠券 (0)<em><i class="imv2-check"></i></em></div>
            <div class="switch-btn code">优惠码<em><i class="imv2-check"></i></em></div>
          </div>
        </div>
      </div>-->
      <div id="Anchor"></div>
      <div class="pay-box clearfix js-total-body-bot fixed">
        <div class="row-bottom">
          <div class="row">
            <div class="goods-total-price-box clearfix">
              <p class="r rw price-num"><em>￥</em><span id="js-actual-price">{{data.cusamountjs}}</span></p>
              <p class="r price-text"><span class="js-total-hide">共<span>{{data.number}}</span>件商品，</span>商品总金额：</p>
            </div>
          </div>
<!--
          <div class="package-discount-box package-discount-box1 clearfix hide">
            <p class="r rw price-num">-<em>￥</em><span id="js-discount-lose-money">{{data.originalprice}}</span></p>
            <p class="r price-text">满减：</p>
          </div>

          &lt;!&ndash; 优惠券/优惠码抵扣 &ndash;&gt;
          <div class="coupons-discount-box clearfix hide">
            <p class="r rw price-num">-<em>￥</em><span id="js-coupon-lose-money"></span></p>
            <p class="r price-text">优惠券/优惠码抵扣：</p>
          </div>
          &lt;!&ndash; 优惠券抵扣end &ndash;&gt;

          <div class="bargain-discount-box clearfix hide">
            <p class="r rw price-num">-<em>￥</em><span id="js-bargain-lose-money"></span></p>
            <p class="r price-text">砍价优惠：</p>
          </div>

          &lt;!&ndash; 组合套餐 or 学生优惠 &ndash;&gt;
          <div class="package-discount-box package-discount-box2 clearfix">
            <p class="r rw price-num">-<em>￥</em><span id="js-discount-lose-money">821.00</span></p>
            <p class="r price-text">优惠折扣：</p>
          </div>
          &lt;!&ndash; 组合套餐 or 学生优惠end &ndash;&gt;

          &lt;!&ndash; 红包 &ndash;&gt;
          <div class="redpackage-discount-box clearfix hide">
            <p class="r rw price-num">-<em>￥</em><span id="js-redpacket-lose-money" data-face="" data-full="">0</span></p>
            <p class="r price-text">红包：</p>
          </div>-->
          <!-- 红包 -->

          <div class="pay-price-box clearfix"><p class="r rw price"><em>￥</em><span id="js-pay-price">{{data.cusamountjs}}</span></p><p class="r price-text">应付：</p></div>
          <a href="javascript:void(0)" class="r moco-btn moco-btn-red submit-btn js-pay-submit" @click="commitOrder" data-typecate="normal">提交订单</a>
         <!-- <el-button type="text" @click="open">您的订单已提交，待确认中，请稍候。。。</el-button>
-->
          <el-button type="text" @click="dialogVisible = true">点击打开 Dialog</el-button>
        </div>
        <div class="pay-add-sign">
          <ul class="clearfix">
            <li>支持花呗</li>
            <li>可开发票</li>
            <li class="drawback">7天可退款<i class="js-check-back imv2-ques"></i><a href="//www.imooc.com/help/detail/105" target="_blank" class="checkbackbtn"><i class="arrow"></i>查看退款规则</a></li>
          </ul>
        </div>
      </div>
    </div>


    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <span>{{ content }}</span>
     <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>-->
    </el-dialog>

  </div>




</template>
<script>
import api from "../../ulti/ajax";

export default {
  data() {
    return {
      tableData: [],
      data: {},
      active: false,
      dialogVisible: false,
      content:"",
      totalTime: 0,
      OrderConfirmListToken:""
    }
  },

  async mounted() {
    await this.getdata();
  },
  async created() {
    document.title='p8课堂-订单确认';
  },
  methods: {
    getdata() {

      this.OrderConfirmListToken="";

      let ids = this.$route.query.ids;
      let type="cart";

      if(ids ==undefined || ids =="undefined" || ids == "" ){
        ids = this.$route.query.courseid;
        type="course";
      }

      console.log("ids2:"+ids+"---type:"+type)

      api.getOrderConfirmList(ids,type).then(response => {
        if (response.data.code == 200) {
          this.tableData = response.data.data.orderitemList;
          this.data = response.data.data;
        }
      });

      api.getOrderConfirmListToken(ids,type).then(response => {
        if (response.data.code == 200) {

          this.OrderConfirmListToken = response.data.msg;
        }
      });



    },

    commitOrder() {
      let ids = this.$route.query.ids;
      let type="cart";

      if(ids ==undefined || ids =="undefined" || ids == "" ){
        ids = this.$route.query.courseid;
        type="course";
      }

      this.totalTime=0;
      api.commitOrder(ids,type,this.OrderConfirmListToken).then(response => {
        if (response.data.code == 200) {
          //console.log("333:"+JSON.stringify(response))
          console.log("333:"+JSON.stringify(response.data))

          let keyid=response.data.msg;
          //alert(JSON.stringify(response.data));
          //alert("订单已提交成功，等待确认中");

          this.content="订单已提交成功，等待确认中。。"
          this.dialogVisible=true;

          let clock = window.setInterval(() => {
            ++this.totalTime
            this.content = keyid+ '订单已提交成功，等待确认中。。已等待'+this.totalTime+"秒";

            api.getOrderStatus(keyid).then(response2 => {


             // console.log("5555:"+JSON.stringify(response2))

              let orders=response2.data.data;
              console.log("orders:"+JSON.stringify(orders))


              if (orders!=undefined && orders.id !=undefined && orders.id>0) {
                window.clearInterval(clock);
                this.dialogVisible=false;
                window.location.href = '#/dd?id=' + orders.id
              }
            });


            if (this.totalTime > 6) {
              window.clearInterval(clock)
            }
          }, 1000)


        }else if(response.data.code == 500){
         /* this.content = "没有抢到"+response.data.msg;
          this.dialogVisible=true;
*/
          this.$message({
            type: 'info',
            message: "没有抢到"+response.data.msg
          });

        }
      });
    },

    open() {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      });
    },

    handleClose(done) {
      this.$confirm('订单还没确认，确认关闭？')
              .then(_ => {
                done();
              })
              .catch(_ => {});
    }


  }

};
</script>

<style>


.body-main {
    min-height: 750px
}

.cart-header {
    height: 160px;
    background-color: #e3e6e9;
    background: url(/static/module/pay/myorder/img/cart-header-bg.jpg) repeat-x left bottom
}

.cart-header .cart-header-warp {
    width: 1104px;
    height: 120px;
    line-height: 120px;
    margin-left: auto;
    margin-right: auto;
    font-size: 14px
}

.cart-header .cart-header-warp .myorder-history {
    font-weight: 200
}

.cart-header .left {
    float: left
}

.cart-header .right {
    float: right
}

.cart-header .cart-title {
    color: #4d555d;
    font-weight: 200;
    font-size: 14px
}

.cart-header .cart-title h1 {
    font-size: 32px;
    line-height: 115px;
    margin-right: 25px;
    color: #07111b;
    font-weight: 200
}

.cart-header .cart-title span {
    margin: 0 4px
}

.cart-header .cart-title .js-number-box-cart {
    line-height: 115px
}

.cart-header .num {
    display: none;
    padding: 4px 5px;
    background-color: #f01414;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 12px;
    line-height: 10px;
    margin-top: 51px;
    margin-left: 5px
}

.cart-body {
    width: 1152px;
    padding: 0 36px 32px;
    background-color: #fff;
    margin-top: -40px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 8px 16px 0 rgba(7,17,27,.1);
    border-radius: 8px;
    box-sizing: border-box
}

.cart-body .left {
    float: left!important
}

.cart-body .right {
    float: right!important
}

.cart-body .check {
    cursor: pointer
}

.cart-body .cart-body-title {
    min-height: 88px;
    line-height: 88px;
    border-bottom: 1px solid #b7bbbf;
    box-sizing: border-box
}

.cart-body .price i {
    float: left
}

.cart-body .notdata {
    padding-top: 160px;
    padding-bottom: 268px;
    font-size: 24px;
    color: #4d555d;
    letter-spacing: 4px;
    line-height: 36px;
    font-weight: 200;
    text-align: center
}

body {
    background: #f8fafc
}

.title-box {
    padding-top: 36px;
    padding-bottom: 24px
}

.title-box .goods-info-title {
    margin-left: 12px;
    color: #07111b;
    font-size: 16px;
    line-height: 16px
}

.title-box a {
    color: #93999f;
    font-size: 12px;
    line-height: 16px
}

.detail-box {
    width: 100%
}

.detail-box .hide {
    visibility: hidden
}

.detail-box ul {
    width: 100%;
    padding-bottom: 36px;
    border-bottom: 1px solid #d9dde1
}

.detail-box ul li {
    padding: 24px;
    box-sizing: border-box;
    background: #f3f5f7;
    margin-bottom: 1px
}

.detail-box ul li img {
    width: 160px;
    height: 90px;
    margin-right: 24px
}

.detail-box ul li .text-info-box {
    width: 462px;
    height: 90px;
    margin-right: 96px
}

.detail-box ul li .text-info-box .info-title {
    margin: 0;
    font-size: 16px;
    color: #07111b;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.detail-box ul li .text-info-box .info-del {
    font-size: 12px;
    color: #93999f;
    line-height: 24px;
    font-weight: 200
}

.detail-box ul li .text-info-box .info-del a {
    margin-left: 12px;
    color: rgba(240,20,20,.4)
}

.detail-box ul li .text-info-box .info-del a:hover {
    color: #f01414
}

.detail-box ul li .text-info-box .class-three-servicetime {
    font-size: 12px;
    color: #93999f;
    text-align: left;
    line-height: 24px
}

.detail-box ul li .text-info-box .class-three-servicetime a {
    margin-left: 14px;
    font-size: 12px;
    color: #0167ff;
    text-align: left;
    line-height: 24px;
    display: inline-block
}

.detail-box ul li .text-info-box .class-three-servicetime a.a-service {
    margin-left: 0!important;
    color: #0167ff
}

.detail-box ul li .text-info-box .package-title {
    margin-top: 8px;
    font-size: 12px;
    color: #07111b;
    line-height: 24px
}

.detail-box ul li .text-info-box .desc-words {
    color: #93999f!important;
    text-align: left
}

.detail-box ul li .text-info-box .desc-words a {
    margin-left: 0!important
}

.detail-box ul li .text-info-box .package-info-title {
    margin: 0;
    font-size: 16px;
    color: #07111b;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.detail-box ul li .text-info-box .package-info-del {
    font-size: 12px;
    color: #93999f;
    line-height: 24px
}

.detail-box ul li .info-price {
    position: relative;
    margin-top: 35px;
    width: 286px;
    font-size: 16px;
    color: #1c1f21;
    font-weight: 700
}

.detail-box ul li .info-price .init-price {
    font-size: 12px;
    color: #93999f;
    text-align: left;
    line-height: 24px;
    font-weight: 400;
    text-decoration: line-through
}

.detail-box ul li .info-price .discount-name {
    display: inline-block;
    background: rgba(242,13,13,.6);
    border-radius: 4px;
    padding: 4px 12px;
    font-size: 12px;
    color: #fff;
    line-height: 16px;
    font-weight: 400
}

.detail-box ul li .info-price .moneyoff-info {
    display: inline-block;
    vertical-align: middle;
    width: 60px;
    height: 24px;
    line-height: 24px;
    box-sizing: border-box;
    border: 1px solid rgba(245,61,61,.2);
    border-radius: 12px;
    text-align: center;
    font-size: 12px;
    margin-top: -2px;
    position: relative;
    padding-left: 6px;
    background: #f53d3d;
    color: #fff
}

.detail-box ul li .info-price .moneyoff-info i {
    display: inline-block;
    position: relative;
    font-size: 16px;
    vertical-align: text-top;
    transition: transform .2s
}

.detail-box ul li .info-price .moneyoff-info .fullreduce-desc {
    display: none;
    text-align: left;
    padding: 16px 20px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%,100%);
    background: #fff;
    box-shadow: 0 2px 8px 0 rgba(28,31,33,.4);
    border-radius: 8px;
    z-index: 1;
    transition: width .3s
}

.detail-box ul li .info-price .moneyoff-info .fullreduce-desc div {
    white-space: nowrap;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 12px;
    font-weight: 700;
    color: #f53d3d
}

.detail-box ul li .info-price .moneyoff-info .fullreduce-desc div.validity-date {
    color: #9199a1;
    font-weight: 400;
    margin-bottom: 0
}

.detail-box ul li .info-price .moneyoff-info .fullreduce-desc::before {
    content: '';
    width: 1px;
    height: 1px;
    border-width: 6px;
    border-style: solid dashed dashed dashed;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    top: -12px;
    left: 50%;
    margin-left: -6px
}

.detail-box ul li .info-price .moneyoff-info:hover .fullreduce-desc {
    display: block
}

.detail-box ul li .info-price .moneyoff-info:hover .imv2-arrow1_d {
    transform: rotate(-180deg)
}

.detail-box ul li:last-child {
    margin-bottom: 0
}

.coupons-box {
    padding-bottom: 31px;
    border-bottom: 1px solid #d9dde1
}

.coupons-box .coupon-title-box {
    margin: 27px 0 0 12px
}

.coupons-box .coupon-title-box .coupon-title {
    color: #07111b;
    font-size: 16px;
    line-height: 16px
}

.coupons-box .coupon-title-box .coupon-title i {
    position: relative;
    top: 5px;
    margin-left: 12px;
    font-size: 24px;
    color: #d9dde1;
    cursor: pointer
}

.coupons-box .coupon-title-box a {
    height: 25px;
    color: #93999f;
    font-size: 12px;
    line-height: 34px
}

.coupons-box .coupon-del-box {
    width: 100%;
    padding: 24px 24px 0 24px;
    box-sizing: border-box
}

.coupons-box .coupon-del-box .coupon-switch-box {
    margin-bottom: 16px
}

.coupons-box .coupon-del-box .coupon-switch-box .switch-btn {
    position: relative;
    display: inline-block;
    width: 138px;
    height: 58px;
    line-height: 20px;
    border: 1px solid #d9dde1;
    border-radius: 8px;
    padding: 18px 0;
    color: #1c1f21;
    text-align: center;
    font-size: 16px;
    margin-right: 16px;
    box-sizing: border-box;
    cursor: pointer
}

.coupons-box .coupon-del-box .coupon-switch-box .switch-btn em {
    display: none;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 0;
    height: 0;
    line-height: 54px;
    border-left-width: 20px;
    border-left-style: solid;
    border-left-color: transparent;
    border-bottom-width: 20px;
    border-bottom-style: solid;
    border-bottom-color: #f01414
}

.coupons-box .coupon-del-box .coupon-switch-box .switch-btn em i {
    color: #fff;
    position: absolute;
    bottom: -20px;
    right: 0;
    font-size: 12px
}

.coupons-box .coupon-del-box .coupon-switch-box .switch-btn.checked {
    border: 2px solid #f01414
}

.coupons-box .coupon-del-box .coupon-switch-box .switch-btn.checked em {
    display: block
}

.coupons-box .coupon-del-box .coupon-content {
    position: relative;
    background: #f3f5f7;
    border-radius: 8px;
    padding: 24px
}

.coupons-box .coupon-del-box .coupon-content:before {
    content: "";
    display: block;
    position: absolute;
    top: -7px;
    left: 62px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 7px solid #f3f5f7
}

.coupons-box .coupon-del-box .coupon-content.ticket ul {
    margin: 0 -7px
}

.coupons-box .coupon-del-box .coupon-content.ticket li {
    padding-top: 8px;
    box-sizing: border-box;
    width: 202px;
    background-color: #fff6f0;
    cursor: pointer;
    margin: 7px
}

.coupons-box .coupon-del-box .coupon-content.ticket li .more-del-box {
    padding: 16px 22px 24px 22px;
    width: 100%;
    box-sizing: border-box;
    background-size: 100%;
    background-image: url(/static/module/pay/confirm/img/coupon-bg.png);
    background-repeat: no-repeat
}

.coupons-box .coupon-del-box .coupon-content.ticket li .price-box {
    height: 32px;
    line-height: 32px
}

.coupons-box .coupon-del-box .coupon-content.ticket li .price-box .price {
    font-size: 30px;
    margin-right: 4px
}

.coupons-box .coupon-del-box .coupon-content.ticket li .price-box .price sub {
    font-size: 24px;
    letter-spacing: -5px
}

.coupons-box .coupon-del-box .coupon-content.ticket li .price-box .use-inst {
    font-size: 12px;
    margin-top: 5px
}

.coupons-box .coupon-del-box .coupon-content.ticket li .use-range {
    margin: 16px 0 12px 0;
    font-size: 12px;
    line-height: 18px;
    min-height: 34px
}

.coupons-box .coupon-del-box .coupon-content.ticket li .use-range .use-ajust-box {
    position: relative;
    height: 16px
}

.coupons-box .coupon-del-box .coupon-content.ticket li .use-range .use-ajust-box .use-ajust-text {
    cursor: pointer;
    position: relative;
    top: -8px
}

.coupons-box .coupon-del-box .coupon-content.ticket li .use-range .use-ajust-box i {
    color: #b9bcbe;
    font-size: 16px;
    position: relative;
    top: 3px;
    left: 0;
    line-height: 24px;
    cursor: pointer
}

.coupons-box .coupon-del-box .coupon-content.ticket li .use-range .use-ajust-box:hover .use-course {
    display: block
}

.coupons-box .coupon-del-box .coupon-content.ticket li .use-range .use-ajust-box .use-course {
    display: none;
    position: absolute;
    top: 16px;
    left: -36px;
    padding: 14px 20px;
    z-index: 3;
    background: #fff;
    -webkit-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    -moz-box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.1);
    box-sizing: border-box
}

.coupons-box .coupon-del-box .coupon-content.ticket li .use-range .use-ajust-box .use-course a {
    width: 302px;
    display: block;
    font-size: 12px;
    line-height: 24px;
    color: #4d555d;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.coupons-box .coupon-del-box .coupon-content.ticket li .use-range .use-ajust-box .use-course a:hover {
    color: #f01414
}

.coupons-box .coupon-del-box .coupon-content.ticket li .use-time-rang {
    font-size: 12px;
    line-height: 16px
}

.coupons-box .coupon-del-box .coupon-content.ticket .can-use .price {
    color: #f01414
}

.coupons-box .coupon-del-box .coupon-content.ticket .can-use .use-inst {
    color: #07111b
}

.coupons-box .coupon-del-box .coupon-content.ticket .can-use .use-range,.coupons-box .coupon-del-box .coupon-content.ticket .can-use .use-time-rang {
    color: #4d555d
}

.coupons-box .coupon-del-box .coupon-content.ticket .cant-use {
    cursor: default;
    background-color: #f3f5f7
}

.coupons-box .coupon-del-box .coupon-content.ticket .cant-use .price,.coupons-box .coupon-del-box .coupon-content.ticket .cant-use .use-inst,.coupons-box .coupon-del-box .coupon-content.ticket .cant-use .use-range,.coupons-box .coupon-del-box .coupon-content.ticket .cant-use .use-time-rang {
    color: #93999f
}

.coupons-box .coupon-del-box .coupon-content.ticket .cant-use .nouse-tip {
    font-size: 12px;
    padding-left: 24px;
    color: #93999f;
    background-color: #fff
}

.coupons-box .coupon-del-box .coupon-content.ticket .cant-use .nouse-tip i {
    position: relative;
    top: 2px;
    font-size: 16px
}

.coupons-box .coupon-del-box .coupon-content.ticket .active {
    position: relative;
    background-color: #f01414
}

.coupons-box .coupon-del-box .coupon-content.ticket .active .price,.coupons-box .coupon-del-box .coupon-content.ticket .active .use-inst,.coupons-box .coupon-del-box .coupon-content.ticket .active .use-range,.coupons-box .coupon-del-box .coupon-content.ticket .active .use-time-rang {
    color: #fff
}

.coupons-box .coupon-del-box .coupon-content.ticket .active .use-range .use-ajust-box i {
    color: #fff
}

.coupons-box .coupon-del-box .coupon-content.ticket .active i {
    position: absolute;
    top: 12px;
    right: 12px;
    color: #fff;
    font-size: 24px
}

.coupons-box .coupon-del-box .coupon-content.ticket .no-coupons {
    font-size: 14px;
    color: #4d555d;
    line-height: 14px
}

.coupons-box .coupon-del-box .coupon-content.code {
    display: none;
    padding-left: 38px
}

.coupons-box .coupon-del-box .coupon-content.code:before {
    left: 216px
}

.coupons-box .coupon-del-box .coupon-content.code .input-box {
    position: relative;
    left: -12px;
    margin-top: 12px
}

.coupons-box .coupon-del-box .coupon-content.code .input-box .convert-input {
    background: #fff;
    border: 1px solid #9199a1;
    width: 356px;
    height: 48px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: 600;
    color: #07111b;
    letter-spacing: 2px;
    line-height: 24px;
    padding: 12px 16px;
    box-sizing: border-box;
    vertical-align: middle
}

.coupons-box .coupon-del-box .coupon-content.code .input-box .convert-btn {
    display: inline-block;
    width: 124px;
    height: 48px;
    line-height: 22px;
    font-size: 16px;
    color: #fff;
    padding: 12px;
    background: #f01414;
    border-radius: 8px;
    margin-left: 24px;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer
}

.coupons-box .coupon-del-box .coupon-content.code .converted-box {
    display: none
}

.coupons-box .coupon-del-box .coupon-content.code .converted-box p {
    line-height: 24px;
    font-size: 16px;
    color: #07111b
}

.coupons-box .coupon-del-box .coupon-content.code .converted-box .c_name,.coupons-box .coupon-del-box .coupon-content.code .converted-box .code-num {
    padding-left: 8px
}

.coupons-box .coupon-del-box .coupon-content.code .converted-box .cancel-btn {
    background: #fff;
    border: 1px solid #d9dde1;
    line-height: 20px;
    padding: 2px 12px;
    text-align: center;
    border-radius: 4px;
    color: #f01414;
    font-size: 14px;
    margin-left: 16px;
    cursor: pointer
}

.coupons-box .coupon-del-box .coupon-content.code .converted-box .course-title {
    font-size: 14px;
    color: #07111b;
    font-weight: 600;
    margin-top: 12px
}

.coupons-box .coupon-del-box .coupon-content.code .converted-box .course-title .discount-cash {
    margin-left: 12px;
    color: #f01414
}

.coupons-box .coupon-del-box .coupon-content.code .error-msg {
    font-size: 14px;
    color: #f01414;
    margin-top: 8px;
    line-height: 20px;
    height: 20px
}

.coupons-box .coupon-del-box .coupon-content.code .tip {
    font-size: 14px;
    color: #93999f;
    margin-top: 8px;
    line-height: 20px
}

.pay-box {
    margin-top: 36px;
    position: relative
}

.pay-box .rw {
    width: 140px;
    box-sizing: border-box;
    text-align: right
}

.pay-box .bargain-discount-box,.pay-box .coupons-discount-box,.pay-box .goods-total-price-box,.pay-box .package-discount-box,.pay-box .redpackage-discount-box,.pay-box .student-discount-box {
    margin-bottom: 12px;
    line-height: 26px
}

.pay-box .bargain-discount-box .price-num,.pay-box .coupons-discount-box .price-num,.pay-box .goods-total-price-box .price-num,.pay-box .package-discount-box .price-num,.pay-box .redpackage-discount-box .price-num,.pay-box .student-discount-box .price-num {
    position: relative;
    font-size: 14px;
    color: #07111b
}

.pay-box .bargain-discount-box .price-text,.pay-box .coupons-discount-box .price-text,.pay-box .goods-total-price-box .price-text,.pay-box .package-discount-box .price-text,.pay-box .redpackage-discount-box .price-text,.pay-box .student-discount-box .price-text {
    text-align: right;
    font-size: 14px;
    color: #07111b
}

.pay-box .bargain-discount-box .price-text span,.pay-box .coupons-discount-box .price-text span,.pay-box .goods-total-price-box .price-text span,.pay-box .package-discount-box .price-text span,.pay-box .redpackage-discount-box .price-text span,.pay-box .student-discount-box .price-text span {
    margin-left: 4px;
    margin-right: 4px
}

.pay-box .pay-add-sign {
    text-align: right;
    position: absolute;
    top: -10px
}

.pay-box .pay-add-sign li {
    float: left;
    padding: 0 12px;
    height: 26px;
    line-height: 26px;
    border: 1px solid #f01414;
    border-radius: 18px;
    font-size: 12px;
    color: #f01414;
    margin-right: 15px
}

.pay-box .pay-add-sign li.drawback {
    position: relative
}

.pay-box .pay-add-sign li.drawback .imv2-ques {
    position: absolute;
    top: -4px;
    right: -2px;
    background: #fff;
    color: #d7dbdf;
    font-size: 14px;
    display: inline-block;
    width: 14px;
    height: 14px;
    cursor: pointer
}

.pay-box .pay-add-sign li.drawback .imv2-ques:hover {
    color: #f20d0d
}

.pay-box .pay-add-sign a.checkbackbtn {
    display: none;
    color: #fff;
    font-size: 12px;
    text-align: center;
    border-radius: 8px;
    vertical-align: top;
    position: absolute;
    left: 100%;
    top: -12px;
    background: rgba(28,31,33,.25);
    width: 100px;
    height: 26px;
    line-height: 26px;
    margin-left: 8px
}

.pay-box .pay-add-sign a.checkbackbtn i.arrow {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 5px solid;
    border-bottom: 5px solid transparent;
    position: absolute;
    left: -5px;
    top: 8px;
    border-right-color: rgba(28,31,33,.25)
}

.pay-box .pay-price-box {
    color: #07111b
}

.pay-box .pay-price-box .price {
    position: relative;
    color: #f01414;
    font-size: 24px;
    font-weight: 700
}

.pay-box .pay-price-box .price span {
    float: none;
    font-weight: 700
}

.pay-box .pay-account {
    font-size: 12px;
    color: #93999f;
    line-height: 24px;
    margin-bottom: 20px;
    margin-top: 15px
}

.pay-box .submit-btn {
    padding: 0;
    width: 140px;
    height: 40px;
    margin-top: 12px;
    text-align: center;
    font-size: 14px;
    line-height: 40px;
    border-radius: 24px
}

.pay-box .disabled {
    background: #ccc;
    cursor: not-allowed;
    border: none
}

.pay-box .presale-wrap {
    text-align: right
}

.pay-box .presale-wrap .submit-btn {
    margin-top: 24px
}

.pay-box .presale-box {
    display: inline-block;
    font-size: 0;
    text-align: left
}

.pay-box .presale-box .step {
    width: 213px;
    padding-bottom: 10px;
    position: relative
}

.pay-box .presale-box .step .title {
    font-size: 14px;
    color: #07111b;
    line-height: 26px
}

.pay-box .presale-box .step .title .price {
    color: #93999f;
    float: right
}

.pay-box .presale-box .step .title .price.active {
    color: #f01414
}

.pay-box .presale-box .step .desc {
    font-size: 12px;
    color: #93999f;
    line-height: 16px
}

.pay-box .presale-box .step:nth-child(3) .price {
    color: #f01414;
    font-size: 24px;
    font-weight: 700
}

.pay-box .presale-box .step .step-line {
    position: absolute;
    top: 8px;
    left: -16px;
    width: 9px;
    display: flex;
    flex-direction: column;
    align-items: center
}

.pay-box .presale-box .step .step-line .circle {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: rgba(147,153,159,.3)
}

.pay-box .presale-box .step .step-line .circle.active {
    background: #f01414
}

.pay-box .presale-box .step .step-line .line {
    height: 43px;
    border-left: 1px dashed rgba(147,153,159,.3)
}

.pay-box .presale-box .step .step-line .line.short {
    height: 27px
}

.pay-box.fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 80px;
    line-height: 80px;
    background-color: #fff;
    z-index: 300;
    box-shadow: 10px -2px 12px rgba(7,17,27,.2)
}

.pay-box.fixed .row-bottom {
    max-width: 1150px;
    position: relative;
    margin: 0 auto
}

.pay-box.fixed .row-bottom .row {
    float: left
}

.pay-box.fixed .row-bottom .bargain-discount-box,.pay-box.fixed .row-bottom .coupons-discount-box,.pay-box.fixed .row-bottom .js-total-hide,.pay-box.fixed .row-bottom .package-discount-box {
    display: none
}

.pay-box.fixed .bargain-discount-box,.pay-box.fixed .coupons-discount-box,.pay-box.fixed .goods-total-price-box,.pay-box.fixed .package-discount-box,.pay-box.fixed .pay-add-sign,.pay-box.fixed .pay-price-box,.pay-box.fixed .redpackage-discount-box {
    float: left;
    margin-bottom: 0
}

.pay-box.fixed .coupons-discount-box,.pay-box.fixed .package-discount-box,.pay-box.fixed .redpackage-discount-box {
    margin-left: 20px
}

.pay-box.fixed .goods-total-price-box {
    width: auto
}

.pay-box.fixed .rw {
    text-align: left;
    width: auto
}

.pay-box.fixed .price,.pay-box.fixed .price-num,.pay-box.fixed .price-text {
    line-height: 80px
}

.pay-box.fixed .pay-add-sign {
    position: static!important;
    margin-left: 20px
}

.pay-box.fixed .pay-add-sign li {
    float: left;
    padding: 0 12px;
    height: 26px;
    line-height: 26px;
    border: 1px solid #f01414;
    border-radius: 18px;
    font-size: 12px;
    color: #f01414;
    margin: 27px 20px 27px 0
}

.pay-box.fixed .pay-price-box {
    width: auto;
    margin-left: 20px
}

.pay-box.fixed .submit-btn {
    margin-top: 16px;
    width: 148px;
    height: 48px;
    line-height: 48px;
    font-size: 16px;
    border-radius: 24px
}

.pay-box.fixed .presale-wrap {
    float: left;
    text-align: left
}

.pay-box.fixed .presale-wrap .presale-box {
    height: 80px;
    display: flex;
    align-items: center
}

.pay-box.fixed .presale-wrap .presale-box .step {
    padding-right: 38px;
    padding-bottom: 0;
    width: auto;
    min-width: 118px;
    height: 45px
}

.pay-box.fixed .presale-wrap .presale-box .step:nth-child(3) {
    height: auto
}

.pay-box.fixed .presale-wrap .presale-box .step .title {
    float: none;
    background: #fff
}

.pay-box.fixed .presale-wrap .presale-box .step .title .price {
    line-height: 26px;
    float: none
}

.pay-box.fixed .presale-wrap .presale-box .step .step-line {
    flex-direction: row;
    width: 100%;
    left: -14px
}

.pay-box.fixed .presale-wrap .presale-box .step .step-line .line {
    border-left: none;
    border-top: 1px dashed rgba(147,153,159,.3);
    width: 30px;
    height: 1px;
    position: absolute;
    right: 5px
}

.pay-box.fixed .presale-wrap .presale-box .step .step-line .circle:nth-child(3) {
    position: absolute;
    right: -10px
}

.bot-right {
    padding-top: 22px
}

.bot-right i {
    background: 0 0;
    box-shadow: none
}

.bot-right .check {
    color: #b7bbbf;
    font-size: 16px;
    position: relative;
    top: 1px
}

.bot-right a {
    color: #008cc8
}

.question-btn {
    display: none;
    position: fixed;
    top: 70%;
    right: 0;
    z-index: 9;
    width: 169px;
    height: 60px;
    background: url(/static/module/common/img/qrcodebtn.png);
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer
}

.js-qrcode-modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    background: rgba(28,31,33,.5)
}

.js-qrcode-modal .qrcode-modal {
    position: relative;
    width: 360px;
    top: 50%;
    left: 50%;
    margin-left: -180px;
    margin-top: -190px;
    padding: 32px 54px;
    background: #fff;
    box-shadow: 0 12px 24px 0 rgba(7,17,27,.2);
    border-radius: 12px;
    text-align: center;
    box-sizing: border-box
}

.js-qrcode-modal .qrcode-modal .qrcode-modal-header .close {
    position: absolute;
    right: 12px;
    top: 12px;
    font-size: 24px;
    color: #b2b8bd;
    line-height: 24px
}

.js-qrcode-modal .qrcode-modal h3 {
    font-size: 20px;
    color: #1c1f21;
    font-weight: 400;
    line-height: 30px
}

.js-qrcode-modal .qrcode-modal p {
    font-size: 14px;
    color: #1c1f21;
    line-height: 24px
}

.js-qrcode-modal .qrcode-modal .tip1 {
    margin-top: 16px
}

.js-qrcode-modal .qrcode-modal .tip2 {
    margin-top: 4px;
    font-size: 12px;
    color: #717a84
}

.js-qrcode-modal .qrcode-modal .qrcode-img {
    margin-top: 16px;
    width: 180px;
    height: 180px
}

</style>
